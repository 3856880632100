<template>
	<div class="qiqb-login">
		<div class="p1"><img src="../../../assets/logo.png" /></div>
		<div class="p2"></div>
		<div class="p3">
			<a-form :form="form">
				<a-form-item>
					<a-input v-decorator="['username', { rules: [{ required: true }] }]" placeholder="请输入登录账号"
						size="large"><a-icon slot="prefix" type="user" style="color:#1890ff" /></a-input>
				</a-form-item>
				<a-form-item>
					<a-input type="password" v-decorator="['password', { rules: [{ required: true }] }]"
						placeholder="请输入登录密码" size="large"><a-icon slot="prefix" type="lock"
							style="color:#1890ff" /></a-input>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" block size="large" @click="next">提交</a-button>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script>
import { login } from "../../../api/index.js";

export default {
	data() {
		return {
			form: this.$form.createForm(this)
		}
	},
	methods: {
		next() {
			this.form.validateFields((errors, values) => {
				if (!errors) {
					login({ account: values.username, pwd: values.password }).then((e) => {
						if (e.status == 200) {
							console.log(e.data.token);
							localStorage.setItem('token', e.data.token)
							localStorage.setItem('name', e.data.name)
							localStorage.setItem('admin', e.data.account)
							// localStorage.setItem('group',e.data.group)								
							// localStorage.setItem('auth',JSON.stringify(e.data.auth))
							// this.$store.commit('login',{id:e.data.id,name:e.data.name,admin:e.data.admin,group:e.data.group,auth:e.data.auth})
							this.$store.commit('token',e.data.token)
							this.$message.success('登录成功');
							setTimeout(() => {
								this.$router.push({ path: '/' })
							}, 500)
						} else {
							this.$message.error(e.detail);
						}
					})
				}
			});
		}
	}
}
</script>

<style>
.qiqb-login .p1 {
	text-align: center;
	padding-top: 100px;
	color: #1890ff;
	font-size: 24px;
}

.qiqb-login .p1 img {
	width: 120px;
}

.qiqb-login .p2 {
	text-align: center;
	padding: 20px 0 50px;
	color: rgba(0, 0, 0, .45);
}

.qiqb-login .p3 {
	width: 328px;
	margin: 0 auto;
}

.qiqb-login .ant-form-item {
	margin-bottom: 20px;
}</style>
